
import _ from 'underscore';
import {parseISO, parse} from 'date-fns';
import Model from '@/model/model';

export class TimeCardEntry extends Model {
    id = null;
    ubcId = null;
    startDate = null;
    endDate = null;
    contractorId = null;
    contractor = null;
    workType = null;
    hoursWorked = null;
    created = null;
    editable = null;

    highlight = false;

    static create(entry) {
        return new TimeCardEntry(entry);
    }

    constructor(entry, asWorkspace) {
        super();
        this.copyFrom(entry);
        this.isWorkspace = true === asWorkspace;

        if (!asWorkspace) {
            this.workspace = new TimeCardEntry(entry, true);
        }
    }

    get fields() {
        return {
            ...super.fields,
            id: 'person_work_hours_id',
            ubcId: 'ubc_id',
            startDate: 'start_date',
            endDate: 'end_date',
            contractorId: 'contractor_id',
            contractor: 'contractor_name',
            workType: 'work_type',
            hoursWorked: 'hours_worked',
            created: 'created',
            editable: 'editable'
        };
    }

    serialize() {
        this.startDate = parse('12:00:00', 'HH:mm:ss', this.startDate);
        this.endDate = parse('12:00:00', 'HH:mm:ss', this.endDate);
        return super.serialize();
    }

    copyFrom(entry) {
        super.copyFrom(entry);

        _.each(['startDate', 'endDate', 'created'], key => {
            const v = this[key];
            const d = _.isDate(v) ? v : parseISO(v);
            this[key] = 'Invalid Date' === d.toString() ? null : d;
        });
    }
}
