import Vue from 'vue';
import Vuex from 'vuex';
import session from './session_store';
import omr from '@/pages/training/store/omr_store';
import timeCard from '@/pages/time_card/store/time_card_store';
import quickLinks from '@/pages/quick_links/store/quick_links_store';
import memberDao from '@/dao/member_dao';
import Member from '@/model/member';
import { PREFERENCES, TrainingCenter } from '@/model/training_center';
import axios from 'axios';
import _ from 'underscore';
import { Country } from '@/model/state';
import { PasswordRule } from '@/model/password_rule';

// TODO: Move this to a constants file
const NYCVDC = 'UBC04112';

Vue.use(Vuex);

const modules = { session, omr, timeCard, quickLinks };

const state = {
  meta: {
    clientVersion: 'unknown',
    serverVersion: 'unknown'
  },
  title: document.title,
  desiredRoute: null,
  loadingRequests: 0,
  currentMember: Member.create({}),
  trainingCenter: TrainingCenter.create({}),
  contractors: [],
  countries: [],
  memberPicture: null,
  changePasswordModal: false,
  showUsernameModal: false,
  passwordRules: null,
  displaySettings: null,
  fieldKeys: {},
  fieldValueKeys: {},
  $vue: null
};

const getters = {
  meta: (state) => state.meta,
  isLocalHost: (state) => state.isLocalHost,
  title: (state) => state.title,
  desiredRoute: (state) => state.desiredRoute,
  loadingRequests: (state) => state.loadingRequests,
  currentMember: (state) => state.currentMember,
  isApprentice: (state) => 'APPRENTICE' === (state.currentMember?.classification || '').trim().toUpperCase(),
  trainingCenter: (state) => state.trainingCenter,
  contractors: (state) => state.contractors,
  memberPicture: (state) => state.memberPicture,
  countries: (state) => state.countries,
  changePasswordModal: (state) => state.changePasswordModal,
  showUsernameModal: (state) => state.showUsernameModal,
  passwordRules: (state) => state.passwordRules,
  canUpdateAddress: (state) => true === state.currentMember?.council?.memberCanUpdateAddress,
  tcCanUpdateAddress: (state) => true === state.currentMember?.council?.tcCanUpdateAddress,
  nycMustUpdateAddress: (state) => NYCVDC === state.currentMember?.council?.id,
  displaySettings: (state) => state.displaySettings,
  fieldKeys: (state) => state.fieldKeys,
  fieldValueKeys: (state) => state.fieldValueKeys,
  hasDisplayField: (state) => (trainingCenter, key, field) => {
    if (!(trainingCenter instanceof TrainingCenter)) {
      return false;
    }

    const displaySettings = trainingCenter.getPreference('displaySettings');
    let section = state.fieldKeys[key];

    if (!section) {
      section = key;
    }

    return _(displaySettings?.value?.[section]).contains(field);
  },
  $vue: (state) => state.$vue,

  tvcLink: (state) => {
    const env = ((state.meta || {}).env || 'dev').toLowerCase();

    let suffix = '';
    switch (env) {
      case 'dev':
      case 'test':
        suffix += `-${env}`;
        break;
      case 'trn':
        suffix += '-training';
        break;
      default:
        break;
    }

    return `https://train${suffix}.carpenters.org/tvc?x=${(state.currentMember || {}).tvcURL || ''}`;
  }
};

const mutations = {
  setMeta: (state, meta) => {
    state.meta = meta;
    state.countries = _(meta.countries).map(Country.create);
    state.passwordRules = PasswordRule.create(meta.passwordRules);
    state.fieldKeys = meta.fieldKeys;
    state.fieldValueKeys = meta.fieldValueKeys;
  },
  setTitle: (state, title) => (state.title = title),
  setDesiredRoute: (state, route) => (state.desiredRoute = route),
  incrementLoadingRequests: (state) => state.loadingRequests++,
  decrementLoadingRequests: (state) => (state.loadingRequests = Math.max(0, state.loadingRequests - 1)),
  setCurrentMember: (state, member) => (state.currentMember = member),
  trainingCenter: (state, trainingCenter) => (state.trainingCenter = trainingCenter),
  contractors: (state, contractors) => (state.contractors = contractors),
  setMemberPicture: (state, picture) => (state.memberPicture = picture),
  toggleChangePasswordModal: (state, show) => (state.changePasswordModal = true === show),
  toggleShowUsernameModal: (state, show) => (state.showUsernameModal = true === show),
  displaySettings: (state, displaySettings) => (state.displaySettings = displaySettings),
  setVue: (state, $vue) => (state.$vue = $vue)
};

const actions = {
  redirectSecureRoute: async (context, route) => {
    context.commit('setDesiredRoute', route);

    try {
      if (_.has((route || {}).query, 'token')) {
        const user = await context.dispatch('session/validateCookie', { override: true, route: route });

        if (!!user) {
          context.dispatch('userValidated', user);
        }
      }
    } catch (ignore) {
      console.warn('Invalid Token - or other error');
    }
  },
  incrementLoadingRequests: (context) => context.commit('incrementLoadingRequests'),
  decrementLoadingRequests: (context) => context.commit('decrementLoadingRequests'),
  loadCurrentMember: async (context, { ubcId, override }) => {
    const currentMember = context.getters.currentMember;

    if (!!currentMember && currentMember.isValid && !override) {
      return;
    }

    const member = await memberDao.getMemberByUbcId(ubcId);
    context.commit('setCurrentMember', member);
  },

  loadTrainingCenter: async (context, { ubcId, override }) => {
    let tc = context.getters.trainingCenter;

    if (!!tc && tc.isValid && !override) {
      return;
    }

    let [t, c] = await memberDao.getTrainingCenter(ubcId);
    context.commit('trainingCenter', t);
    context.dispatch('setContractors', c);
    context.dispatch('setPreferences');
  },

  setContractors: (context, contractors) => {
    context.commit('contractors', contractors);
  },

  loadMemberPicture: async (context, { ubcId, override }) => {
    if (!!context.getters.memberPicture && !override) {
      return;
    }

    try {
      const picture = await memberDao.getMemberPicture(ubcId);
      context.commit('setMemberPicture', picture);
    } catch (err) {
      context.dispatch('showError', { err });
    }
  },

  userValidated: async (context, user) => {
    context.dispatch('loadCurrentMember', { ubcId: user.ubcId });
    context.dispatch('loadTrainingCenter', { ubcId: user.ubcId });
    context.dispatch('loadMemberPicture', { ubcId: user.ubcId });
  },

  destroy: async (context) => {
    ['setCurrentMember', 'trainingCenter', 'setMemberPicture'].forEach((key) => context.commit(key, null));
  },

  showError: (context, { err, title }) => {
    context.rootGetters.$vue.$bvToast.toast(err.message, {
      title: title || 'Error',
      solid: true,
      variant: 'danger'
    });

    return false;
  },

  setPreferences: (context) => {
    const tc = context.getters.trainingCenter || {};

    if (!_(tc.getPreference).isFunction()) {
      // TODO: Maybe reset the preference values
      return;
    }

    Object.keys(PREFERENCES).forEach((preferenceKey) => {
      const [clientKey] = PREFERENCES[preferenceKey];
      const value = (tc.getPreference(clientKey) || {}).value;

      if (_(context.getters).has(clientKey)) {
        context.commit(clientKey, value);
      }

      ['quickLinks', 'timeCard', 'omr'].forEach((storeName) => {
        const storeKey = `${storeName}/${clientKey}`;

        if (_(context.rootGetters).has(storeKey)) {
          context.commit(storeKey, value, { root: true });
        }
      });
    });
  },

  showChangePasswordModal: ({ commit }) => {
    commit('toggleChangePasswordModal', true);
  },

  hideChangePasswordModal: ({ commit }) => {
    commit('toggleChangePasswordModal', false);
  },

  saveContactInformation: async (context, member) => {
    return memberDao.saveContactInformation(member);
  },

  saveEmailAddress: async (context, member) => {
    return memberDao.saveEmailAddress(member);
  },

  requestEmailVerification: async (context, member) => {
    return memberDao.requestEmailVerification(member);
  }
};

export default async function getStore() {
  const rootStore = new Vuex.Store({
    modules,
    state,
    getters,
    mutations,
    actions
  });

  axios
    .get('/api/meta.json')
    .then(({ data }) => {
      rootStore.commit('setMeta', { ...rootStore.getters.meta, ...data });
      // TODO: These will be set in the backend and won't be needed for much longer
      rootStore.commit('session/cookieName', data.cookieName || 'SESSION');
      rootStore.commit('session/cookieLifetime', `${parseInt(data.cookieLifetime, 10) || 10800}s`);
    })
    .catch(() => console.warn('Failed to get server meta'));

  axios
    .get('/meta.json')
    .then(({ data }) => rootStore.commit('setMeta', { ...rootStore.getters.meta, ...data }))
    .catch(() => console.warn('Failed to get client meta'));

  return rootStore;
}
