import quickLinksDao from '@/pages/quick_links/dao/quick_links_dao';

const state = {
  links: [],
  loaded: false
};

const getters = {
  links: state => state.links,
  loaded: state => state.loaded
};

const mutations = {
  links: (state, links) => state.links = links,
  loaded: (state, loaded) => state.loaded = loaded
};

const actions = {
  getQuickLinks: async (context) => {
    try {

      const results = await quickLinksDao.getQuickLinks();
      context.commit('links', results);
      context.commit('loaded', true);

    } catch (err) {
      context.dispatch('showError', { err }, { root: true });
    }
  },

  destroy: (context) => context.commit('links', [])
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
