
import nacl from 'tweetnacl';
import {
    decodeUTF8,
    encodeUTF8,
    decodeBase64,
    encodeBase64
} from 'tweetnacl-util';


function mkNonce() {
    return nacl.randomBytes(nacl.secretbox.nonceLength);
}


export function encrypt(recipientPublicKey, senderSecretKey, message) {

    const nonce = mkNonce();
    const data = decodeUTF8(message);
    const sharedKey = nacl.box.before(recipientPublicKey, senderSecretKey);
    const encrypted = nacl.box.after(data, nonce, sharedKey);
    const payload = new Uint8Array(nonce.length + encrypted.length);
    payload.set(nonce);
    payload.set(encrypted, nonce.length);

    return encodeBase64(payload);
}

export function decrypt(recipientSecretKey, senderPublicKey, data) {
    const encrypted = decodeBase64(data);
    const nonce = encrypted.slice(0, nacl.box.nonceLength);
    const message = encrypted.slice(nacl.box.nonceLength, encrypted.length);

    const sharedKey = nacl.box.before(senderPublicKey, recipientSecretKey);
    const decrypted = nacl.box.open.after(message, nonce, sharedKey);

    return encodeUTF8(decrypted);
}
