import Model from '@/model/model';

export class Charset extends Model {
  ordinal = null;
  rule = null;
  description = null;
  range = null;
  compiledRule = null;

  static create(charset) {
    return new Charset(charset);
  }

  constructor(charset) {
    super();
    this.copyFrom(charset);
  }

  get fields() {
    return {
      ...super.fields,
      ordinal: 'ordinal',
      rule: 'rule',
      description: 'description',
      range: 'range'
    };
  }

  test(value) {
    return (this.compiledRule?.test?.(value)) ?? false;
  }

  copyFrom(charset) {
    super.copyFrom(charset);

    const r = this.range ? this.rule : this.rule.replace('-', '\\-');
    const pattern = r.replace('[', '\\[').replace(']', '\\]');
    this.compiledRule = new RegExp(`[${pattern}]`);
  }
}

export class PasswordRule extends Model {
  minLength = null;
  minCharsets = null;
  charsets = [];

  static create(rule) {
    return new PasswordRule(rule);
  }

  constructor(rule) {
    super();
    this.copyFrom(rule);
  }

  get fields() {
    return {
      ...super.fields,
      minLength: 'min_length',
      minCharsets: 'min_charsets'
    };
  }

  copyFrom(rule) {
    super.copyFrom(rule);

    this.charsets = (rule.charsets || []).map(Charset.create);
  }
}
