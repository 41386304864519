import Model from '@/model/model';
import {parseISO} from 'date-fns';
import _ from 'underscore';

export default class Certificate extends Model {
    id = null;
    name = null;
    number = null;
    certificateDate = null;
    expirationDate = null;

    static create(certificate) {
        return new Certificate(certificate);
    }

    constructor(certificate) {
        super();
        this.copyFrom(certificate);
    }

    get fields() {
        return {
            ...super.fields,
            id: 'certification_id',
            name: 'certification_name',
            number: 'certification_number',
            certificateDate: 'certification_date',
            expirationDate: 'expiration_date'
        };
    }

    copyFrom(certificate) {
        super.copyFrom(certificate);

        _.each(['certificateDate', 'expirationDate'], key => {
            const v = this[key];
            const d = _.isDate(v) ? v : parseISO(v);
            this[key] = 'Invalid Date' === d.toString() ? null : d;
        });
    }
}