<template>
  <b-overlay :show="showLoading" variant="dark" class="loading-shade" rounded>
    <slot></slot>
  </b-overlay>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class LoadingShade extends Vue {
  get showLoading() {
    return this.$store.getters.loadingRequests > 0;
  }
}
</script>
<style scoped></style>
