import Model from '@/model/model';
import { parseISO } from 'date-fns';
import _ from 'underscore';

export default class Request extends Model {
  id = null;
  ubcId = null;
  requestDate = null;
  processedDate = null;
  status = null;
  classId = null;
  classScheduleId = null;
  className = null;
  startDate = null;
  endDate = null;
  craft = null;
  meetingTimes = null;
  seatsAvailable = null;
  trainingCenterName = null;

  static create(request) {
    return new Request(request);
  }

  constructor(request) {
    super();
    this.copyFrom(request);
  }

  get fields() {
    return {
      ...super.fields,
      id: 'request_id',
      ubcId: 'ubc_id',
      requestDate: 'request_date',
      processedDate: 'processed_date',
      status: 'request_status',
      classId: 'class_id',
      classScheduleId: 'class_schedule_id',
      className: 'class_name',
      startDate: 'start_date',
      endDate: 'end_date',
      craft: 'craft',
      meetingTimes: 'meeting_times',
      seatsAvailable: 'seats_available',
      trainingCenterName: 'training_center_name'
    };
  }

  copyFrom(request) {
    super.copyFrom(request);

    _.each(['requestDate', 'processedDate', 'startDate', 'endDate'], key => {
      const v = this[key];
      const d = _.isDate(v) ? v : parseISO(v);
      this[key] = 'Invalid Date' === d.toString() ? null : d;
    });
  }
}