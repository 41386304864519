import _ from 'underscore';
import Model from '@/model/model';

export class TrainingCenterPreference {
  preference = null;
  value = null;

  constructor(preference, value) {
    this.preference = preference;
    const possibleNumber = parseInt(value, 10);

    switch (true) {
      case _(value).isBoolean():
        this.value = value;
        break;

      case _(value).isString():
        this.value = 'Y' === value.toUpperCase();
        break;

      case !_(possibleNumber).isNaN():
        this.value = possibleNumber;
        break;

      case _(value).isObject():
        this.value = value;
        break;

      default:
        console.warn('Un-parsable value for [%s = %s]', preference, value);
        break;
    }
  }

  equals(other) {
    return !!other && (other === this.preference || other.preference === this.preference);
  }
}

export const PREFERENCES = {
  ENABLE_OMR: ['enableOMR', 'enable_online_member_registration'],
  ENABLE_TIME_CARD: ['enableTimeCard', 'enable_time_card_entry'],
  ENABLE_WORK_TYPE: ['enableWorkType', 'enable_work_type'],
  REQUIRE_WORK_TYPE: ['requireWorkType', 'require_work_type'],
  REQUIRE_CURRICULUM: ['requireCurriculum', 'require_curriculum'],
  REQUIRE_CONTRACTOR: ['requireContractor', 'require_contractor'],
  MAY_NOT_EXCEED: ['mayNotExceed', 'may_not_exceed_days'],
  ENTERED_DAYS: ['enteredDays', 'entered_days'],
  MODIFIED_DAYS: ['modifiedDays', 'modified_days'],
  DISPLAY_TOTAL_WORK_HOURS: ['displayTotalWorkHours', 'display_total_work_hours'],
  DISPLAY_WORK_HOURS_SINCE_UPGRADE: ['displayWorkHoursSinceUpgrade', 'display_work_hours_since_upgrade'],
  DISPLAY_SETTINGS: ['displaySettings', 'display_settings']
};

export class TrainingCenter extends Model {
  jatcId = null;
  name = null;
  address1 = null;
  address2 = null;
  city = null;
  state = null;
  zip = null;
  phoneNumber = null;
  emailAddress = null;

  preferences = [];
  workTypes = [];

  static create(trainingCenter) {
    return new TrainingCenter(trainingCenter);
  }

  constructor(trainingCenter) {
    super();
    this.copyFrom(trainingCenter);
  }

  get fields() {
    return {
      ...super.fields,
      jatcId: 'jatc_id',
      name: 'name',
      address1: 'address1',
      address2: 'address2',
      city: 'city',
      state: 'state',
      zip: 'zip',
      phoneNumber: 'phone_number',
      emailAddress: 'email_address',
      workTypes: 'work_types'
    };
  }

  copyFrom(trainingCenter) {
    const tc = _(trainingCenter).isObject() ? trainingCenter : {};
    super.copyFrom(tc);

    Object.keys(PREFERENCES).forEach((key) => {
      const [clientKey, serverKey] = PREFERENCES[key];

      let pref = null;

      switch (true) {
        case tc instanceof TrainingCenter:
          pref = tc.getPreference(clientKey);
          break;

        case _(tc?.settings).has(clientKey):
          pref = tc.settings[clientKey];
          break;

        default:
          pref = tc?.settings?.[serverKey];
          break;
      }

      this.addPreference(clientKey, pref);
    });
  }

  addPreference(preference, value) {
    const pref = new TrainingCenterPreference(preference, value);
    const exists = this.getPreference(pref);
    if (exists) {
      exists.value = pref.value;
    } else {
      this.preferences.push(pref);
    }

    return this;
  }

  getPreference(preference) {
    return _.find(this.preferences, (p) => p.equals(preference));
  }

  get isValid() {
    return (parseInt(this.jatcId, 10) || 0) > 0;
  }
}

export default TrainingCenter;
