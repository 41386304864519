import downloadjs from 'downloadjs';
import Axios from 'axios';
import _ from 'underscore';

class Downloader {
  _store = null;

  _showShade() {
    if (!!this._store && _.isFunction(this._store.dispatch)) {
      this._store.dispatch('incrementLoadingRequests');
    }
  }

  _hideShade() {
    if (!!this._store && _.isFunction(this._store.dispatch)) {
      this._store.dispatch('decrementLoadingRequests');
    }
  }

  async _download(method, url, body, filename) {
    // TODO: POST doesn't actually do anything yet

    this._showShade();

    try {
      const response = await Axios({
        url,
        method,
        responseType: 'blob'
      });

      downloadjs(response.data, filename);
    } catch (err) {
      const data = (err.response || {}).data || null;
      let msg = 'Unknown Error';

      if (data instanceof Blob) {
        msg = await data.text();

        try {
          const parsed = JSON.parse(msg);
          msg = parsed.detail || 'Unknown Error';
        } catch (ignore) {
          console.warn('Expected JSON, got: ', msg);
        }
      }

      throw new Error(msg);
    } finally {
      this._hideShade();
    }
  }

  async get(url, filename) {
    return this._download('GET', url, null, filename);
  }

  setStore(store) {
    this._store = store;
  }
}

const downloader = new Downloader();

export default downloader;
