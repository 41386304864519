import timeCardDao from '@/pages/time_card/dao/time_card_dao';
import _ from 'underscore';
import { sub as subDate } from 'date-fns';

const TODAY = new Date();

const state = {
  entries: [],
  entriesLoaded: false,
  enableTimeCard: false,
  enableWorkType: false,
  requireWorkType: false,
  requireContractor: false,
  displayTotalWorkHours: false,
  displayWorkHoursSinceUpgrade: false,
  mayNotExceed: 0,
  enteredDays: 0,
  modifiedDays: 0
};

const getters = {
  entries: (state) => state.entries,
  entriesLoaded: (state) => state.entriesLoaded,
  enableTimeCard: (state) => state.enableTimeCard,
  enableWorkType: (state) => state.enableWorkType,
  requireWorkType: (state) => state.requireWorkType,
  requireContractor: (state) => state.requireContractor,
  displayTotalWorkHours: (state) => state.displayTotalWorkHours,
  displayWorkHoursSinceUpgrade: (state) => state.displayWorkHoursSinceUpgrade,
  mayNotExceed: (state) => state.mayNotExceed,
  enteredDays: (state) => state.enteredDays,
  modifiedDays: (state) => state.modifiedDays,
  minEditDate: (state) => subDate(TODAY, { days: state.enteredDays }),
  maxEditDate: (state) => TODAY
};

const mutations = {
  entries: (state, entries) => (state.entries = entries),
  entriesLoaded: (state, loaded) => (state.entriesLoaded = loaded),
  enableTimeCard: (state, enableTimeCard) => (state.enableTimeCard = enableTimeCard),
  enableWorkType: (state, enableWorkType) => (state.enableWorkType = enableWorkType),
  requireWorkType: (state, requireWorkType) => (state.requireWorkType = requireWorkType),
  requireContractor: (state, requireContractor) => (state.requireContractor = requireContractor),
  displayTotalWorkHours: (state, displayTotalWorkHours) => (state.displayTotalWorkHours = displayTotalWorkHours),
  displayWorkHoursSinceUpgrade: (state, displayWorkHoursSinceUpgrade) => (state.displayWorkHoursSinceUpgrade = displayWorkHoursSinceUpgrade),
  mayNotExceed: (state, mayNotExceed) => (state.mayNotExceed = mayNotExceed),
  enteredDays: (state, enteredDays) => (state.enteredDays = enteredDays),
  modifiedDays: (state, modifiedDays) => (state.modifiedDays = modifiedDays),
  addTimeCardEntry: (state, entry) => state.entries.push(entry),
  removeTimeCardEntry: (state, entry) => {
    const idx = _.findIndex(state.entries, (e) => e.id === entry.id);
    if (0 > idx) {
      return;
    }
    state.entries.splice(idx, 1);
  }
};

const actions = {
  loadEntries: async (context, { ubcId }) => {
    try {
      const entries = await timeCardDao.getEntries(ubcId);
      await context.commit('entries', entries);
      await context.dispatch('setContractors');
      await context.commit('entriesLoaded', true);
      return true;
    } catch (err) {
      await context.dispatch('showError', { err, title: 'Unable to Load Time Card Entries' }, { root: true });
      return false;
    }
  },

  setContractors: (context) => {
    const tcContractors = context.rootGetters.contractors;
    const whContractors = _.map(context.getters.entries, (e) => ({
      id: e.contractorId,
      name: e.contractor
    }));

    const weights = _.reduce(
      whContractors,
      (w, c) => {
        if (!_.has(w, c.id)) {
          w[c.id] = 1;
        }
        w[c.id]++;
        return w;
      },
      {}
    );

    const contractors = _([...tcContractors, ...whContractors])
      .chain()
      .unique((c) => c.id)
      .map((c) => {
        c.weight = 'Other' === c.name ? 1 : _.has(weights, c.id) ? weights[c.id] : 0;
        return c;
      })
      .sortBy((c) => c.name) // First sort them by name
      .reverse()
      .sortBy((c) => c.weight) // Then sort them by weight
      .reverse()
      .value();

    context.dispatch('setContractors', contractors, { root: true });
  },

  saveEntry: async (context, entry) => {
    try {
      return await timeCardDao.saveEntry(entry);
    } catch (err) {
      return context.dispatch('showError', { err, title: 'Error Saving Time Entry' }, { root: true });
    }
  },

  addEntry: async (context, entry) => {
    const savedEntry = await context.dispatch('saveEntry', entry);
    if (savedEntry) {
      context.commit('addTimeCardEntry', savedEntry);
    }

    return savedEntry;
  },

  deleteEntry: async (context, entry) => {
    try {
      const deletedEntry = await timeCardDao.deleteEntry(entry);
      context.commit('removeTimeCardEntry', entry);
    } catch (err) {
      return context.dispatch('showError', { err, title: 'Error Deleting Time Entry' }, { root: true });
    }
  },

  destroy: (context) => {
    //Tear down the state
    context.commit('entries', []);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
