import _ from 'underscore';
import Dao from '@/dao/dao';
import AvailableClass from '@/pages/training/model/available_class';
import Request from '@/pages/training/model/request';
import TrainingHistory from '@/pages/training/model/training_history';
import Certificate from '@/pages/training/model/certificate';
import RequiredClass from '@/pages/training/model/required_class';
import UpcomingClass from '@/pages/training/model/upcoming_class';
import LmsUpdate from '@/pages/training/model/LmsUpdate';

class ClassesDao extends Dao {

  async getAvailableClasses(ubcId, jatcId) {
    try {

      const { data } = await this.api.get(`/omr/available_classes/${ubcId}/${jatcId}`);
      return _(data).map(AvailableClass.create);

    } catch (error) {
      console.error(error.response);
    }
  }

  async makeRequest(ubcId, classScheduleId) {

    try {
      const url = `/omr/request/${ubcId}/${classScheduleId}`;
      const response = await this.api.put(url);
      return Request.create(response.data);
    } catch (err) {
      throw new Error(err.response);
    }
  }

  async getRequests(ubcId) {
    try {
      const url = `/omr/requests/${ubcId}`;
      const response = await this.api.get(url);
      return _.map(response.data, Request.create);
    } catch (err) {
      throw new Error(err.response);
    }
  }

  async cancelRequest(ubcId, requestId) {
    try {

      const url = `/omr/requests/${ubcId}/${requestId}`;
      const response = await this.api.delete(url);
      return Request.create(response.data);

    } catch (err) {
      throw new Error(err.response);
    }
  }

  async getTrainingHistory(ubcId) {
    try {
      const url = `/omr/training_history/${ubcId}`;
      const response = await this.api.get(url);
      return _.map(response.data, TrainingHistory.create);
    } catch (err) {
      throw new Error(err.response);
    }
  }

  async getCertifications(ubcId) {
    try {
      const url = `/omr/certifications/${ubcId}`;
      const response = await this.api.get(url);

      return _.reduce(['welding', 'additional'], (p, k) => {
        p[k] = _.map(response.data[k], Certificate.create);
        return p;
      }, {});

    } catch (err) {
      throw new Error(err.response);
    }
  }

  async getRequiredCurriculum(jatcId, ubcId) {
    try {

      const url = `/omr/curriculum/${jatcId}/${ubcId}`;
      const response = await this.api.get(url);

      return _.map(response.data, RequiredClass.create);

    } catch (err) {
      throw new Error(err.response)
    }
  }

  async getUpcomingClasses(ubcId) {
    try {

      const url = `/omr/upcoming/classes/${ubcId}`;
      const response = await this.api.get(url);

      return _.map(response.data, UpcomingClass.create);

    } catch (err) {
      throw new Error(err.response);
    }
  }

  async getLmsUpdates(ubcId) {
    try {
      const url = `/omr/lms/updates/${ubcId}`
      const response = await this.api.get(url);
      return _.map(response.data, LmsUpdate.create);

    } catch (err) {
      throw new Error(err.response);
    }
  }
}

export default new ClassesDao();
