import { parseISO, isValid, format as fnsFormat, formatISO } from 'date-fns';

function mkdate(input) {

  if (isValid(input)) {
    return input;
  }

  const dt = parseISO(input);
  return isValid(dt) ? dt : null;
}

function format(input, pattern, defaultValue = '') {
  const dt = mkdate(input);

  if (!dt) {
    return defaultValue;
  }

  if (!pattern) {
    return formatISO(dt);
  }

  return fnsFormat(dt, pattern);
}

export { parseISO, isValid, format, mkdate };
