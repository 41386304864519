import Dao from '@/dao/dao';

class QuickLinksDao extends Dao {
  async getQuickLinks() {
    try {

      const { data } = await this.api.get('/quick_links');
      return data

    } catch (err) {
      this.handleError(err);
    }
  }
}

export default new QuickLinksDao();
