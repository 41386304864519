import Model from '@/model/model';
import Address from '@/model/address';
import _ from 'underscore';

class Organization extends Model {
  id = null;
  name = null;
  address = null;
  phoneNumber = null;

  constructor() {
    super();
  }

  get fields() {
    return {
      ...super.fields,
      id: 'id',
      name: 'name',
      phoneNumber: 'phone_number',
      address: 'address'
    };
  }

  copyFrom(organization) {
    super.copyFrom(organization);

    if (_(organization?.address).isObject()) {
      this.address = Address.create(organization.address);
    }
  }
}

export class Local extends Organization {
  static create(local) {
    return new Local(local);
  }

  constructor(local) {
    super();
    this.copyFrom(local);
  }
}

export class Council extends Organization {
  memberCanUpdateAddress = null;
  tcCanUpdateAddress = null;
  displayDuesCode = null;
  displayBalanceOwed = null;

  static create(council) {
    return new Council(council);
  }

  constructor(council) {
    super();
    this.copyFrom(council);
  }

  get fields() {
    return {
      ...super.fields,
      memberCanUpdateAddress: 'member_can_update_address',
      tcCanUpdateAddress: 'tc_can_update_address',
      displayDuesCode: 'display_dues_code',
      displayBalanceOwed: 'display_balance_owed'
    };
  }
}
