import Model from '@/model/model';
import { mkdate } from '@/util/date';
import _ from 'underscore';
import { Local, Council } from '@/model/organization';
import Address from '@/model/address';

export class EmergencyContact extends Model {
  name = null;
  phone = null;
  relationship = null;
  address = null;

  static create(emergencyContact) {
    return new EmergencyContact(emergencyContact);
  }

  constructor(emergencyContact) {
    super();
    this.copyFrom(emergencyContact);
  }

  get fields() {
    return {
      ...super.fields,
      name: 'name',
      phone: 'phone',
      relationship: 'relationship',
      address: 'address'
    };
  }

  copyFrom(emergencyContact) {
    super.copyFrom(emergencyContact);

    this.address = _(emergencyContact.address).isObject() ? Address.create(emergencyContact.address) : null;
  }
}

export class Member extends Model {
  ubcId = null;
  firstName = null;
  middleName = null;
  lastName = null;
  suffix = null;
  affiliate = null;
  local = null;
  council = null;
  classification = null;
  trainClassification = null;
  craft = null;
  tvcURL = null;
  lmsEligible = null;

  primaryAddress = null;
  alternateAddress = null;
  primaryPhoneNumber = null;
  mobilePhoneNumber = null;
  emailAddress = null;
  emailVerified = null;

  emergencyContact = null;

  status = null;

  specialties = null;

  indentDate = null;
  contractor = null;
  studentStatus = null;
  duesCode = null;
  currentBalance = null;
  lastWorkMonth = null;
  workHoursSinceUpgrade = null;

  totalWorkHours = null;
  classTotal = null;

  upgradeWorkHours = null;
  classUpgrade = null;

  neededWorkHours = null;
  classNeeded = null;

  classTypeRequirement = null;

  currentLevel = null;
  currentPercent = null;
  wageRate = null;
  lastUpgradeDate = null;
  nextUpgradeDate = null;
  expectedGraduationDate = null;
  otherUpgradeRequirements = null;
  requiredClasses = null;

  static create(member) {
    return new Member(member);
  }

  constructor(member, asWorkspace = false) {
    super();
    this.copyFrom(member);
    this.isWorkspace = true === asWorkspace;

    if (!asWorkspace) {
      this.workspace = new Member(member, true);
    }
  }

  get fields() {
    return {
      ...super.fields,
      ubcId: 'ubc_id',
      firstName: 'first_name',
      middleName: 'middle_name',
      lastName: 'last_name',
      suffix: 'suffix',
      affiliate: 'affiliate',
      local: 'local',
      council: 'council',
      classification: 'classification',
      trainClassification: 'train_classification',
      craft: 'craft',
      tvcURL: 'tvc_url',
      lmsEligible: 'lms_eligible',
      status: 'status',
      paidThroughDate: 'paid_through_date',
      initDate: 'init_date',
      tcCanUpdateAddress: 'tc_can_update_address',
      primaryAddress: 'primary_address',
      alternateAddress: 'alternate_address',
      primaryPhoneNumber: 'primary_phone_number',
      mobilePhoneNumber: 'mobile_phone',
      emailAddress: 'primary_email_address',
      emailVerified: 'email_verified',
      emergencyContact: 'emergency_contact',
      specialties: 'specialties',
      indentDate: 'indent_date',
      contractor: 'contractor',
      studentStatus: 'student_status',
      duesCode: 'dues_code',
      currentBalance: 'current_balance',
      lastWorkMonth: 'last_work_month',
      workHoursSinceUpgrade: 'work_hours_since_upgrade',

      // Total Units
      totalWorkHours: 'total_work_hours',
      classTotal: 'class_total',

      // Upgrade Units
      upgradeWorkHours: 'upgrade_work_hours',
      classUpgrade: 'class_upgrade',

      // Graduation Units
      neededWorkHours: 'needed_work_hours',
      classNeeded: 'class_needed',

      classTypeRequirement: 'class_type_requirement',

      currentLevel: 'current_level',
      currentPercent: 'current_percent',
      wageRate: 'wage_rate',
      lastUpgradeDate: 'last_upgrade_date',
      nextUpgradeDate: 'next_upgrade_date',
      expectedGraduationDate: 'expected_graduation_date',
      otherUpgradeRequirements: 'other_upgrade_requirements',
      requiredClasses: 'requried_classes'
    };
  }

  get fullName() {
    return `${this.firstName}${!!this.middleName ? ' ' + this.middleName : ''} ${this.lastName}${!!this.suffix ? ' ' + this.suffix : ''}`;
  }

  get isValid() {
    return !!this.ubcId;
  }

  copyFrom(member) {
    super.copyFrom(member);

    this.paidThroughDate = mkdate(this.paidThroughDate);
    this.initDate = mkdate(this.initDate);
    this.indentDate = mkdate(this.indentDate);

    this.local = _(member.local).isObject() ? Local.create(member.local) : null;
    this.council = _(member.council).isObject() ? Council.create(member.council) : null;

    this.primaryAddress = _(member.primaryAddress).isObject()
      ? Address.create(member.primaryAddress)
      : _(member.primary_address).isObject()
        ? Address.create(member.primary_address)
        : null;

    this.alternateAddress = _(member.alternateAddress).isObject()
      ? Address.create(member.alternateAddress)
      : _(member.alternate_address).isObject()
        ? Address.create(member.alternate_address)
        : null;

    this.emergencyContact = _(member.emergencyContact).isObject()
      ? EmergencyContact.create(member.emergencyContact)
      : _(member.emergency_contact).isObject()
        ? EmergencyContact.create(member.emergency_contact)
        : null;
  }
}

export default Member;
