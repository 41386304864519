import _ from 'underscore';
import Model from '@/model/model';

export default class Contractor extends Model {
    id = null;
    name = null;

    static create(contractor) {
        return new Contractor(contractor);
    }

    constructor(contractor) {
        super();
        this.copyFrom(contractor);
    }

    get fields() {
        return {
            ...super.fields,
            id: 'contractor_id',
            name: 'contractor_name'
        }
    }
}