import Vue from 'vue';
import VueRouter from 'vue-router';
import _ from 'underscore';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    meta: { secure: true },
    redirect: { name: 'training' }
  },
  {
    path: '/contact/update',
    name: 'contact-information-form',
    meta: { secure: true },
    component: () => import(/* webpackChunkName: "training" */ '@/views/details/ContactInformationForm')
  },
  {
    path: '/about',
    name: 'about',
    meta: { secure: true },
    component: () => import(/* webpackChunkName: "about" */ '@/pages/about/About')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import(/* webpackChunkName: "loginView" */ '@/views/LoginView')
  },
  {
    path: '/:ubcId(U-?\\d{4}-?\\d{4})',
    meta: { secure: true },
    component: () => import(/* webpackChunkName: "unionDetails" */ '@/views/details/UnionDetails'),
    children: [
      {
        path: 'training',
        name: 'training',
        meta: { secure: true },
        component: () => import(/* webpackChunkName: "training" */ '@/pages/training/views/Training')
      },
      {
        path: 'training/:page',
        name: 'training_link',
        props: true,
        meta: { secure: true },
        component: () => import(/* webpackChunkName: "training" */ '@/pages/training/views/Training')
      },
      {
        path: 'time_card',
        name: 'time_card',
        meta: { secure: true },
        component: () => import(/* webpackChunkName: "timeCard" */ '@/pages/time_card/TimeCard')
      }
    ]
  }
];

let appRouter = null;

const defaultPage = (router, store) => {
  return router.replace({
    name: 'training_link',
    params: {
      ubcId: store.getters['session/currentUser']?.ubcId,
      page: 'membership'
    }
  });
};

export default function getRouter(store) {
  if (!_.isNull(appRouter)) {
    return appRouter;
  }

  const router = (appRouter = new VueRouter({
    mode: 'history',
    base: '/app',
    routes
  }));

  store.watch(
    (state) => {
      return (state.session || {}).loggedIn;
    },
    (loggedIn) => {
      if (loggedIn) {
        Vue.nextTick(() => {
          const currentRoute = router.currentRoute;
          const desiredRoute = store.getters.desiredRoute;

          if (desiredRoute && currentRoute.name !== desiredRoute.name) {
            store.dispatch('redirectSecureRoute', null);
            return router.replace(desiredRoute);
          }

          if ('login' == currentRoute.name) {
            return defaultPage(router, store);
          }
        });
      }
    }
  );

  router.beforeEach((to, from, next) => {
    const loggedIn = store.getters['session/isLoggedIn'];

    if (to.meta.secure && !loggedIn) {
      store.dispatch('redirectSecureRoute', to);
      return next({ name: 'login' });
    }

    if ('training' === to.name) {
      return defaultPage(router, store);
    }

    next();
  });

  return router;
}
