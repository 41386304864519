import Model from '@/model/model';
import {parseISO} from 'date-fns';
import _ from 'underscore';

export default class AvailableClass extends Model {
    classId = null;
    classScheduleId = null;
    className = null;
    startDate = null;
    endDate = null;
    craft = null;
    meetingTimes = null;
    seatsAvailable = null;
    trainingCenterName = null;

    static create(clazz) {
        return new AvailableClass(clazz);
    }

    constructor(clazz) {
        super();
        this.copyFrom(clazz);
    }

    get fields() {
        return {
            ...super.fields,
            classId: 'class_id',
            classScheduleId: 'class_schedule_id',
            className: 'class_name',
            startDate: 'start_date',
            endDate: 'end_date',
            craft: 'craft',
            meetingTimes: 'meeting_times',
            seatsAvailable: 'seats_available',
            trainingCenterName: 'training_center_name'
        }
    }

    copyFrom(clazz) {
        super.copyFrom(clazz);

        _.each(['startDate', 'endDate'], key => {
            const v = this[key];
            const d = _.isDate(v) ? v : parseISO(v);
            this[key] = 'Invalid Date' === d.toString() ? null : d;
        });
    }
}