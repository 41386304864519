import Dao from '@/dao/dao';
import TrainingCenter from '@/model/training_center';
import Contractor from '@/model/contractor';
import Member from '@/model/member';
import _ from 'underscore';

class MemberDao extends Dao {
  async getMemberByUbcId(ubcId) {
    try {
      const response = await this.api.get(`/member/lookup/${ubcId}`);
      return Member.create(response.data);
    } catch (error) {
      this.handleError(error);
    }
  }

  async getMemberPicture(ubcId) {
    try {
      const response = await this.api.get(`/member/picture/${ubcId}`);
      return response.data.picture;
    } catch (error) {
      this.handleError(error);
    }
  }

  async getTrainingCenter(ubcId) {
    try {
      const response = await this.api.get(`/member/training_center/${ubcId}`);
      const payload = response.data;

      return !payload ? [null, []] : [TrainingCenter.create(payload), _(payload.contractors).map(Contractor.create)];
    } catch (error) {
      this.handleError(error);
    }
  }

  async saveContactInformation(member) {
    try {
      await this.api.post('/member/contact/info', member);
    } catch (error) {
      this.handleError(error);
    }
  }

  async saveEmailAddress(member) {
    try {
      await this.api.post('/member/email', member);
    } catch (error) {
      this.handleError(error);
    }
  }

  async requestEmailVerification(member) {
    try {
      await this.api.post('/member/verify/email', member);
    } catch (error) {
      this.handleError(error);
    }
  }
}

export default new MemberDao();
