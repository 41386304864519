

const UBCID_REGEX = /^U-?(\d{4})-?(\d{4})$/i;


function isValid(value) {
    return UBCID_REGEX.test(value);
}

function withHyphens(value) {
    return (value || '').replace(UBCID_REGEX, 'U-$1-$2');
}

function withoutHyphens(value) {
    return (value || '').replace(UBCID_REGEX, 'U$1$2');
}


export {
    isValid,
    UBCID_REGEX,
    withHyphens,
    withoutHyphens
};
