import _ from 'underscore';
import classesDao from '@/pages/training/dao/classes_dao';
import AvailableClass from '@/pages/training/model/available_class';

const state = {
  enableOMR: false,
  requireCurriculum: false,
  availableClasses: [],
  requests: [],
  trainingHistory: [],
  certifications: {
    welding: [],
    additional: []
  },
  upcomingClasses: [],
  lmsUpdates: [],
  curriculum: []
};

const getters = {
  enableOMR: (state) => state.enableOMR,
  requireCurriculum: (state) => state.requireCurriculum,
  availableClasses: (state) => state.availableClasses,
  requests: (state) => state.requests,
  trainingHistory: (state) => state.trainingHistory,
  certifications: (state) => state.certifications,
  upcomingClasses: (state) => state.upcomingClasses,
  lmsUpdates: (state) => state.lmsUpdates,
  curriculum: (state) => state.curriculum
};

const mutations = {
  enableOMR: (state, enable) => (state.enableOMR = enable),
  requireCurriculum: (state, requireCurriculum) => (state.requireCurriculum = requireCurriculum),
  availableClasses: (state, classes) => (state.availableClasses = classes),
  requests: (state, requests) => (state.requests = requests),
  addRequest: (state, request) => {
    state.requests.push(request);
    const idx = _.findIndex(state.availableClasses, (c) => c.classScheduleId === request.classScheduleId);
    if (idx >= 0) {
      state.availableClasses.splice(idx, 1);
    }
  },

  addClass: (state, clazz) => {
    if (_.any(state.availableClasses, (c) => c.classScheduleId === clazz.classScheduleId)) {
      return;
    }

    state.availableClasses.push(clazz);
  },

  updateRequest: (state, ur) => {
    const request = _.find(state.requests, (r) => r.id === ur.id);

    if (!request) {
      return;
    }

    request.copyFrom(ur);
  },

  trainingHistory: (state, trainingHistory) => (state.trainingHistory = trainingHistory),
  weldingCertifications: (state, certifications) => (state.certifications.welding = certifications),
  additionalCertifications: (state, certifications) => (state.certifications.additional = certifications),
  upcomingClasses: (state, upcomingClasses) => (state.upcomingClasses = upcomingClasses),
  lmsUpdates: (state, lmsUpdates) => (state.lmsUpdates = lmsUpdates),
  curriculum: (state, curriculum) => (state.curriculum = curriculum)
};

const actions = {
  loadAvailableClasses: async (context, { ubcId, jatcId, override }) => {
    if (!_(context.getters.availableClasses).isEmpty() && !override) {
      return;
    }

    try {
      const classes = await classesDao.getAvailableClasses(ubcId, jatcId);
      context.commit(
        'availableClasses',
        _(classes).map((c) => {
          c._showDetails = false;
          return c;
        })
      );
    } catch (err) {
      context.dispatch('showError', { err }, { root: true });
    }
  },

  loadRequests: async (context, { ubcId, override }) => {
    if (!_.isEmpty(context.getters.requests) && !override) {
      return;
    }

    try {
      const requests = await classesDao.getRequests(ubcId);
      context.commit('requests', requests);
    } catch (err) {
      context.dispatch('showError', { err }, { root: true });
    }
  },

  loadTrainingHistory: async (context, { ubcId, override }) => {
    if (!_.isEmpty(context.getters.trainingHistory) && !override) {
      return;
    }

    try {
      context.commit('trainingHistory', []);

      const trainingHistory = await classesDao.getTrainingHistory(ubcId);
      context.commit('trainingHistory', trainingHistory);
    } catch (err) {
      context.dispatch('showError', { err }, { root: true });
    }
  },

  loadCertifications: async (context, { ubcId, override }) => {
    if (!_.isEmpty(context.getters.certifications.welding) && !_.isEmpty(context.getters.certifications.additional) && !override) {
      return;
    }

    try {
      context.commit('weldingCertifications', []);
      context.commit('additionalCertifications', []);

      const certifications = await classesDao.getCertifications(ubcId);
      context.commit('weldingCertifications', certifications.welding);
      context.commit('additionalCertifications', certifications.additional);
    } catch (err) {
      context.dispatch('showError', { err }, { root: true });
    }
  },

  loadUpcomingClasses: async (context, { ubcId, override }) => {
    if (!_.isEmpty(context.getters.upcomingClasses) && !override) {
      return;
    }

    try {
      context.commit('upcomingClasses', []);

      const upcomingClasses = await classesDao.getUpcomingClasses(ubcId);
      context.commit('upcomingClasses', upcomingClasses);
    } catch (err) {
      context.dispatch('showError', { err }, { root: true });
    }
  },

  loadLmsUpdates: async (context, { ubcId, override }) => {
    if (!_.isEmpty(context.getters.lmsUpdates) && !override) {
      return;
    }

    try {
      context.commit('lmsUpdates', []);

      const lmsUpdates = await classesDao.getLmsUpdates(ubcId);
      context.commit('lmsUpdates', lmsUpdates);
    } catch (err) {
      context.dispatch('showError', { err }, { root: true });
    }
  },

  loadRequiredCurriculum: async (context, { ubcId, jatcId, override }) => {
    if (!_.isEmpty(context.getters.curriciulum) && !override) {
      return;
    }

    try {
      const curriculum = await classesDao.getRequiredCurriculum(jatcId, ubcId);
      context.commit('curriculum', curriculum);
    } catch (err) {
      context.dispatch('showError', { err }, { root: true });
    }
  },

  makeRequest: async (context, { ubcId, classScheduleId }) => {
    try {
      const regRequest = await classesDao.makeRequest(ubcId, classScheduleId);
      context.commit('addRequest', regRequest);
    } catch (err) {
      context.dispatch('showError', { err }, { root: true });
    }
  },

  cancelRequest: async (context, { ubcId, requestId }) => {
    try {
      const updatedRequest = await classesDao.cancelRequest(ubcId, requestId);
      context.commit('updateRequest', updatedRequest);

      if ('Cancelled by Member' === updatedRequest.status) {
        context.commit('addClass', AvailableClass.create(updatedRequest));
      }
    } catch (err) {
      context.dispatch('showError', { err }, { root: true });
    }
  },

  destroy: (context) => {
    [
      'availableClasses',
      'requests',
      'trainingHistory',
      'weldingCertifications',
      'additionalCertifications',
      'upcomingClasses',
      'lmsUpdates',
      'curriculum'
    ].forEach((key) => context.commit(key, []));
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
