<template>
  <div class="container-fluid">
    <b-card no-body class="border-0 p-0">
      <loading-shade>
        <main-menu />
        <router-view />
        <page-footer />
        <change-password-modal v-model="changePasswordModal" />
        <show-username-modal v-model="showUsername" />
      </loading-shade>
    </b-card>
    <div class="text-center app-info text-muted">Client {{ meta.clientVersion }} | Server {{ meta.serverVersion }}</div>
  </div>
</template>
<script>
import { Component, Vue } from 'vue-property-decorator';
import LoadingShade from '@/components/LoadingShade';
import MainMenu from '@/components/MainMenu';
import PageFooter from '@/components/PageFooter';
import ChangePasswordModal from '@/components/ChangePasswordModal';
import ShowUsernameModal from '@/components/ShowUsernameModal';

@Component({
  components: {
    LoadingShade,
    MainMenu,
    PageFooter,
    ChangePasswordModal,
    ShowUsernameModal
  }
})
export default class App extends Vue {
  get isLoggedIn() {
    return this.$store.getters['session/isLoggedIn'];
  }

  get meta() {
    return this.$store.getters.meta || {};
  }

  get changePasswordModal() {
    return this.$store.getters.changePasswordModal;
  }

  set changePasswordModal(cpm) {
    this.$store.commit('toggleChangePasswordModal', cpm);
  }

  get showUsername() {
    return this.$store.getters.ShowUsernameModal;
  }

  set showUsername(sun) {
    this.$store.commit('toggleShowUsernameModal', sun);
  }

  async created() {
    try {
      await this.$store.dispatch('session/validateCookie', { override: true });
    } catch (err) {
      console.warn(err);
    }
  }
}
</script>
<style>
body {
  font-family: sans-serif;
  background-color: #2a2a2a !important;
}

div.app {
  background-color: white;
  padding: 0;
  margin-top: 10px;
}

div.app-info {
  color: white;
  font-size: 10px;
}

svg.icon-button {
  cursor: pointer;
}

div.filter-heading {
  cursor: pointer;
}

div.card-header.hour-group button {
  border: 1px solid black;
  color: white;
  font-weight: bold;
  text-shadow: 1px 1px 1px black;
}

div.card-header.year-group button {
  background-color: #a28058;
}

div.card-header.month-group button {
  background-color: #888;
}
</style>
