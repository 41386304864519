import _ from 'underscore';
import nacl from 'tweetnacl';
import { decodeBase64, encodeBase64 } from 'tweetnacl-util';
import User from '@/model/user';
import { getApi } from '@/service/api';
import { encrypt } from '@/util/crypto';
import { getQueryParam } from '@/util/query';

const api = getApi();

const state = {
  cookieName: 'SESSION',
  cookieLifetime: '3h',
  initValidation: false,
  loggedIn: false,
  user: new User({}),
  serverKey: null,
  clientKey: nacl.box.keyPair(),
  authChecked: false
};

const getters = {
  cookieName: (state) => state.cookieName,
  cookieLifetime: (state) => state.cookieLifetime,
  validationDone: (state) => state.initValidation,
  isLoggedIn: (state) => state.loggedIn,
  currentUser: (state) => state.user,
  serverKey: (state) => state.serverKey,
  clientKey: (state) => state.clientKey,
  authChecked: (state) => state.authChecked
};

const mutations = {
  cookieName: (state, cookieName) => (state.cookieName = cookieName),
  cookieLifetime: (state, cookieLifetime) => (state.cookieLifetime = cookieLifetime),
  startValidation: (state) => (state.initValidation = true),
  stopValidation: (state) => (state.initValidation = false),
  destroySession: (state) => {
    state.loggedIn = false;
    state.user = null;
  },
  setUser: (state, user) => (state.user = user),
  setLoggedIn: (state, loggedIn) => (state.loggedIn = loggedIn),
  setServerKey: (state, key) => (state.serverKey = decodeBase64(key)),
  setAuthChecked: (state, authChecked) => (state.authChecked = authChecked)
};

const actions = {
  refreshKey: async (context) => {
    try {
      const response = await api.get('/portal/public_key');

      if (200 !== response?.status) {
        console.error('Failed to get server key! - encryption will not work');
        // TODO: Handle this better
        return;
      }

      context.commit('setServerKey', response.data);

      return context.getters.serverKey;
    } catch (err) {
      console.error(err);
      // TODO: look into displaying a message maybe
      return null;
    }
  },

  changePassword: async (context, { oldPassword, newPassword }) => {
    await context.dispatch('refreshKey');
    const serverKey = context.getters.serverKey;
    const clientKey = context.getters.clientKey;
    const ubcId = context.getters.currentUser?.ubcId;
    const credentials = { ubcId, oldPassword, newPassword };
    const message = JSON.stringify(credentials);
    const encrypted = encrypt(serverKey, clientKey.secretKey, message);
    const data = {
      key: encodeBase64(clientKey.publicKey),
      credentials: encrypted
    };

    try {
      await api.post('/portal/change/password', data);
    } catch (error) {
      throw new Error(error?.detail || 'Unknown Error');
    }
  },

  login: async (context, { identity, password, redirect }) => {
    await context.dispatch('refreshKey');
    const serverKey = context.getters.serverKey;
    const clientKey = context.getters.clientKey;
    const credentials = { identity, password };
    const message = JSON.stringify(credentials);
    const encrypted = encrypt(serverKey, clientKey.secretKey, message);
    const data = {
      key: encodeBase64(clientKey.publicKey),
      credentials: encrypted
    };

    try {
      const response = await api.put('/portal/login', data);

      if (200 === response?.status && !redirect) {
        const user = User.create(response.data.user);
        context.commit('setUser', user);
        await context.dispatch('userValidated', context.getters.currentUser, { root: true });
        context.commit('setLoggedIn', true);
      }
    } catch (error) {
      const response = error.respose;
      const data = response?.data;

      switch (response?.status) {
        case 403:
          throw new Error(data?.detail);
        default:
          throw new Error('Could not authenticate, please try again');
      }
    }
  },

  logout: async (context, route) => {
    try {
      const response = await api.delete('/portal/logout');

      if (200 === response?.status) {
        context.commit('destroySession');
      }
    } catch (error) {
      // TODO: Do something with error
      console.error(error);
    }
  },

  validateCookie: async (context, { override }) => {
    if (context.getters.validationDone && !override) {
      return false;
    }

    context.commit('startValidation');

    try {
      // Need to make sure we have the right server key
      await context.dispatch('refreshKey');

      const params = { reqId: Date.now() };
      const token = getQueryParam('token');

      const payload = {};

      if (!!token) {
        payload.token = token;
      }
      const response = await api.post('/portal/validate_cookie', payload);

      const user = User.create(response.data.user);
      context.commit('setUser', user);
      await context.dispatch('userValidated', user, { root: true });
      context.commit('setLoggedIn', true);

      return user;
    } catch (err) {
      console.warn(err);
      return false;
    } finally {
      setTimeout(() => {
        context.commit('setAuthChecked', true);
      }, 300);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
