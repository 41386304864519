import Model from '@/model/model';
import {parseISO} from 'date-fns';
import _ from "underscore";

export default class AbstractClass extends Model {
    className = null;
    startDate = null;
    endDate = null;

    constructor(clazz) {
        super();
    }

    get fields() {
        return {
            ...super.fields,
            className: 'class_name',
            startDate: 'start_date',
            endDate: 'end_date'
        }
    }

    copyFrom(clazz) {
        super.copyFrom(clazz);

        _.each(['startDate', 'endDate'], key => {
            const v = this[key];
            const d = _.isDate(v) ? v : parseISO(v);
            this[key] = 'Invalid Date' === d.toString() ? null : d;
        });
    }
}
