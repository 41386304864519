
export function getQueryParams() {
  const search = document.location.search?.substring(1); // Strip off the leading '?'
  const kvs = search.split('&');
  return kvs.reduce((vs, kv) => {
    const parts = kv.split('=', 2);
    const value = parts.pop()?.replace(/ /g, '+')?.replace(/%20/g, '+');
    const key = parts.pop();
    vs[key] = value;
    return vs;
  }, {});
}

export function getQueryParam(key) {
  const params = getQueryParams();
  return params[key];
}