import AbstractClass from './abstract_class';

export default class UpcomingClass extends AbstractClass {
    meetingTimes = null;
    location = null;

    static create(clazz) {
        return new UpcomingClass(clazz);
    }

    constructor(clazz) {
        super();
        this.copyFrom(clazz)
    }

    get fields() {
        return {
            ...super.fields,
            meetingTimes: 'meeting_times',
            location: 'class_location'
        }
    }
}
