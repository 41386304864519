<template>
  <b-navbar toggleable="md" type="dark" class="page-header">
    <b-navbar-brand :to="{ name: 'training', params: { ubcId } }">
      <b-img src="@/assets/ubc_logo_small.png" alt="United Brotherhood of Carpenters" class="page-logo" />
      <span class="page-title">Member Portal</span>
    </b-navbar-brand>
    <b-navbar-toggle target="nav-collapse" />
    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav class="main-menu-items">
        <template v-if="isLoggedIn">
          <b-nav-item class="main-menu-item main-menu-link" :to="{ name: 'about' }">About</b-nav-item>
          <b-nav-item class="main-menu-item main-menu-link" :to="{ name: 'training_link', params: { ubcId, page: 'membership' } }">
            Membership
          </b-nav-item>
          <b-nav-item class="main-menu-item main-menu-link" :to="{ name: 'training_link', params: { ubcId, page: 'training-history' } }">
            Training
          </b-nav-item>
          <b-nav-item class="main-menu-item main-menu-link" :to="{ name: 'time_card', params: { ubcId } }">Time Card</b-nav-item>
        </template>
        <b-button size="sm" :variant="envVariant" class="main-menu-item" v-if="'prod' !== env">{{ env }} environment</b-button>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto" style="white-space: nowrap" v-if="isLoggedIn">
        <b-nav-item-dropdown right>
          <template #button-content>
            <b-avatar variant="primary" :src="picture" />
            <span class="ml-2 text-white main-menu-item main-menu-greeting">Hello {{ currentMember.firstName }} {{ currentMember.lastName }}!</span>
          </template>
          <b-dropdown-item class="main-menu-item main-menu-link" :to="{ name: 'contact-information-form' }">
            Update Contact Information
          </b-dropdown-item>
          <b-dropdown-item class="main-menu-item main-menu-link" @click="$store.commit('toggleChangePasswordModal', true)">
            Change Password
          </b-dropdown-item>
          <b-dropdown-item class="main-menu-item main-menu-link" @click="$store.commit('toggleShowUsernameModal', true)">
            Request Username
          </b-dropdown-item>
          <b-dropdown-item class="main-menu-item main-menu-link" @click="logout">Log Out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator';
import { withoutHyphens } from '@/util/ubc_id';

@Component
export default class MainMenu extends Vue {
  get user() {
    return this.$store.getters['session/currentUser'];
  }

  get currentMember() {
    return this.$store.getters.currentMember || {};
  }

  get picture() {
    return this.$store.getters.memberPicture;
  }

  get ubcId() {
    return withoutHyphens((this.user || {}).ubcId);
  }

  get isLoggedIn() {
    return this.$store.getters['session/isLoggedIn'];
  }

  get meta() {
    return this.$store.getters.meta || {};
  }

  get env() {
    return this.meta.env || 'dev';
  }

  get envVariant() {
    switch (this.env) {
      case 'dev':
        return 'danger';
      case 'test':
        return 'primary';
      case 'training':
        return 'warning';
      default:
        return 'success';
    }
  }

  async logout() {
    this.$store.dispatch('incrementLoadingRequests');

    try {
      await this.$store.dispatch('session/logout', this.$route);
      this.$store.dispatch('destroy');
      this.$store.dispatch('omr/destroy');
      this.$store.dispatch('timeCard/destroy');

      if (this.$route.name !== 'login') {
        this.$router.push({ name: 'login' });
      }
    } catch (e) {
      // TODO: alert failure
      console.error(e);
    } finally {
      this.$store.dispatch('decrementLoadingRequests');
    }
  }
}
</script>
<style scoped>
li.main-menu-item a {
  color: white !important;
}

li.main-menu-greeting {
  font-weight: bold;
  text-shadow: 1px 1px 0 black;
}

li.main-menu-link a:hover {
  color: #ffc107 !important;
  text-decoration: underline;
  text-shadow: 1px 1px 0 black;
}

.page-header {
  background-color: #a28058;
  color: white;
  margin: 0;
  border-bottom: 1px solid black;
}
.page-title {
  font-weight: bold;
  text-shadow: 1px 1px 0 black;
  margin-left: 5px;
}
.page-logo {
  max-height: 45px;
}
</style>
