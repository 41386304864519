import Model from '@/model/model';

export default class LmsUpdate extends Model {
    name = null;
    completionDate = null;

    static create(update) {
        return new LmsUpdate(update);
    }

    constructor(update) {
        super();
        this.copyFrom(update);
    }

    get fields() {
        return {
            ...super.fields,
            name: 'lms_item_name',
            completionDate: 'completion_date'
        }
    }
}
