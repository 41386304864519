<template>
  <b-modal
    title="Change Your Password"
    body-bg-variant="light"
    header-bg-variant="dark"
    header-text-variant="white"
    footer-bg-variant="dark"
    footer-text-variant="white"
    @show="onShow"
    v-model="isShown"
    centered>
    <b-form-group label="Old Password:" label-cols-sm="5" label-align-sm="right">
      <b-input-group>
        <b-input :type="passwordVisible ? 'text' : 'password'" v-model="oldPassword" :state="validated('oldPassword')" />
        <b-input-group-addon>
          <b-button size="sm" @click="passwordVisible = !passwordVisible" tabindex="-1">
            <b-icon :icon="passwordVisible ? 'eye' : 'eye-slash'" />
          </b-button>
        </b-input-group-addon>
        <b-form-invalid-feedback>Old Password is Required</b-form-invalid-feedback>
      </b-input-group>
    </b-form-group>
    <b-form-group label="New Password:" label-cols-sm="5" label-align-sm="right">
      <b-input-group>
        <b-input :type="passwordVisible ? 'text' : 'password'" v-model="newPassword" :state="validated('newPassword')" />
        <b-input-group-addon>
          <b-button size="sm" @click="passwordVisible = !passwordVisible" tabindex="-1">
            <b-icon :icon="passwordVisible ? 'eye' : 'eye-slash'" />
          </b-button>
        </b-input-group-addon>
        <b-form-invalid-feedback>{{ newPasswordFeedback }}</b-form-invalid-feedback>
      </b-input-group>
    </b-form-group>
    <b-form-group label="Repeat New Password:" label-cols-sm="5" label-align-sm="right">
      <b-input-group>
        <b-input :type="passwordVisible ? 'text' : 'password'" v-model="newPasswordRepeat" :state="validated('newPasswordRepeat')" />
        <b-input-group-addon>
          <b-button size="sm" @click="passwordVisible = !passwordVisible" tabindex="-1">
            <b-icon :icon="passwordVisible ? 'eye' : 'eye-slash'" />
          </b-button>
        </b-input-group-addon>
        <b-form-invalid-feedback>Passwords do not match</b-form-invalid-feedback>
      </b-input-group>
    </b-form-group>
    <b-form-group label="" label-cols-sm="5" label-align-sm="right">
      <b-button-group size="sm">
        <b-button variant="success" :disabled="showShade || changeButtonDisabled" @click="onChangeClick">Change Password</b-button>
        <b-button variant="danger" :disabled="showShade" @click="isShown = false">Cancel</b-button>
      </b-button-group>
    </b-form-group>
    <template #modal-footer>
      <b-card no-body class="border-0 w-100" header-bg-variant="dark" header-class="border-0 p-0 m-0">
        <template #header>
          <div class="clearfix" :class="{ 'pb-2': showPasswordRules }">
            <b-button size="sm" variant="link" @click="showPasswordRules = !showPasswordRules" class="float-right p-0">
              <b-icon :icon="'chevron-double-' + (showPasswordRules ? 'up' : 'down')" />
            </b-button>
            <b-icon :icon="(isStrongPassword ? 'check' : 'x') + '-circle'" :variant="passwordStrengthVariant" />
            <strong :class="'ml-2 text-' + passwordStrengthVariant">Password Rules</strong>
          </div>
        </template>
        <b-collapse v-model="showPasswordRules" class="border-0">
          <b-list-group flush class="border-0">
            <b-list-group-item class="border-0" :variant="newPassword.length >= passwordRules.minLength ? 'success' : 'danger'">
              Your password must be at least {{ passwordRules.minLength }} characters long.
            </b-list-group-item>
            <b-list-group-item :variant="!newPasswordFeedback ? 'success' : 'danger'">
              Your password must contain characters from at least {{ passwordRules.minCharsets }} of the following
              {{ passwordRules.charsets.length }} groups:
              <b-alert show :variant="charset.test(newPassword) ? 'success' : 'danger'" v-for="(charset, i) in passwordCharsets" :key="i">
                {{ charset.description }}: {{ charset.rule }}
              </b-alert>
            </b-list-group-item>
          </b-list-group>
        </b-collapse>
      </b-card>
    </template>
  </b-modal>
</template>
<script>
import { Vue, Component, Prop } from 'vue-property-decorator';
import _ from 'underscore';

@Component
export default class ChangePasswordModal extends Vue {
  @Prop({ type: Boolean, default: false }) value;
  passwordVisible = false;
  oldPassword = null;
  newPassword = null;
  newPasswordRepeat = null;

  showShade = false;
  showPasswordRules = false;

  get passwordRules() {
    return this.$store.getters.passwordRules || {};
  }

  get passwordCharsets() {
    return _(this.passwordRules?.charsets || []).sortBy((cs) => cs.ordinal);
  }

  get newPasswordValue() {
    return (this.newPassword || '').trim();
  }

  get passwordStrengthCount() {
    return this.passwordCharsets.reduce((sum, cs) => sum + (cs.test(this.newPasswordValue) ? 1 : 0), 0);
  }

  get isStrongPassword() {
    return this.newPasswordValue.length >= this.passwordRules.minLength && this.passwordStrengthCount >= this.passwordRules.minCharsets;
  }

  get passwordStrengthVariant() {
    return this.isStrongPassword ? 'success' : 'danger';
  }

  get isShown() {
    return this.value;
  }

  set isShown(value) {
    this.$emit('input', value);
  }

  async onChangeClick() {
    if (!this.isStrongPassword) {
      return this.$bvToast.toast('Please correct the highlighted errors.', {
        title: 'Error',
        solid: true,
        variant: 'danger'
      });
    }

    try {
      this.showShade = true;
      await this.$store.dispatch('session/changePassword', {
        oldPassword: this.oldPassword,
        newPassword: this.newPassword
      });
      this.isShown = false;

      this.$bvToast.toast('You have successfully changed your password!', {
        title: 'Password Changed',
        solid: true,
        variant: 'success'
      });
    } catch (error) {
      const message = error && error.message ? error.message : 'An unknown error occurred, please try again';

      this.$bvToast.toast(message, {
        title: 'Error',
        solid: true,
        variant: 'danger'
      });
    } finally {
      this.showShade = false;
    }
  }

  get changeButtonDisabled() {
    return ['oldPassword', 'newPassword', 'newPasswordRepeat'].some((k) => !this.validated(k));
  }

  get newPasswordFeedback() {
    const value = (this.newPassword || '').trim();

    if (0 === value.length) {
      return 'New Password is required';
    }

    if (value.length < this.passwordRules.minLength) {
      return 'New Password is too short';
    }

    if (this.passwordStrengthCount < this.passwordRules.minCharsets) {
      return 'New Password does not meet the complexity requirements';
    }

    return false;
  }

  validated(field) {
    const value = (this[field] || '').trim();
    switch (field) {
      case 'oldPassword':
        return 0 < value.length;
      case 'newPassword':
        return !this.newPasswordFeedback;
      case 'newPasswordRepeat':
        if (_((this.newPassword ?? '').trim()).isEmpty()) {
          return undefined;
        }

        return !_((this.newPasswordRepeat ?? '').trim()).isEmpty() && this.newPassword === this.newPasswordRepeat;
      default:
        return true;
    }
  }

  onShow() {
    ['oldPassword', 'newPassword', 'newPasswordRepeat'].forEach((k) => (this[k] = ''));
    this.showPasswordRules = false;
  }
}
</script>
