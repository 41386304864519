import Model from '@/model/model';
import _ from 'underscore';

export class State extends Model {
  id = null;
  name = null;
  abbreviation = null;
  countryId = null;

  static create(state) {
    return new State(state);
  }

  constructor(state) {
    super();
    this.copyFrom(state);
  }

  get fields() {
    return {
      ...super.fields,
      id: 'id',
      name: 'name',
      abbreviation: 'abbreviation',
      countryId: 'country_id'
    };
  }
}

export class Country extends Model {
  id = null;
  name = null;
  abbreviation = null;
  states = [];

  static create(country) {
    return new Country(country);
  }

  constructor(country) {
    super();
    this.copyFrom(country);
  }

  get fields() {
    return {
      ...super.fields,
      id: 'id',
      name: 'name',
      abbreviation: 'abbreviation'
    };
  }

  copyFrom(country) {
    super.copyFrom(country);

    const states = _(country?.states || []);
    this.states = states.isArray() ? states.map(State.create) : [];
  }
}
