import AbstractClass from './abstract_class';

export default class RequiredClass extends AbstractClass {
    classId = null;
    classScheduleId = null;
    programNames = null;
    status = null;
    required = null;
    year = null;
    classCredit = null;
    finalGrade = null;

    static create(clazz) {
        return new RequiredClass(clazz);
    }

    constructor(clazz) {
        super();
        this.copyFrom(clazz);
    }

    get fields() {
        return {
            ...super.fields,
            classId: 'class_id',
            classScheduleId: 'class_schedule_id',
            className: 'class_name',
            programNames: 'program_names',
            status: 'status',
            required: 'ubc_required_flg',
            year: 'apprentice_year',
            startDate: 'start_date',
            endDate: 'end_date',
            classCredit: 'class_credit',
            finalGrade: 'final_grade'
        };
    }

    copyFrom(clazz) {
        super.copyFrom(clazz);
        this.required = 'Y' === (this.required || '').toUpperCase();
    }
}