import _ from 'underscore';
import Dao from '@/dao/dao';
import { TimeCardEntry } from '@/pages/time_card/model/time_card';

class TimeCardDao extends Dao {
  async getEntries(ubcId) {
    try {
      const url = `/time_card/entries/${ubcId}`;
      const response = await this.api.get(url);
      return _.map(response.data, TimeCardEntry.create);
    } catch (err) {
      this.handleError(err);
    }
  }

  async saveEntry(entry) {

    try {
      const url = '/time_card/entry';
      const body = entry.serialize();
      const response = await this.api.post(url, body);
      return TimeCardEntry.create(response.data);
    } catch (err) {
      this.handleError(err);
    }
  }

  async deleteEntry(entry) {
    try {
      const url = '/time_card/entry';
      const data = entry.serialize();
      await this.api.delete(url, { data });
    } catch (err) {
      this.handleError(err);
    }
  }
}

export default new TimeCardDao();
