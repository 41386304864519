import { getApi } from '@/service/api';
import _ from 'underscore';

export default class Dao {
  constructor() {
    this.api = getApi();
  }

  handleError(err) {
    const detail = err.response?.data?.detail || err.detail;

    if (!detail) {
      console.error(err);
      throw new Error('Unknown Error');
    }

    const wrapped = _(detail);

    switch (true) {
      case wrapped.isArray():
        const det = wrapped.first();
        throw new Error(_(det).isObject() ? det.msg || det.message : 'Unknown Error');
      case _(detail).isObject():
        throw new Error(wrapped.isObject() ? detail.msg || detail.message : 'Unknown Error');
      case wrapped.isString():
        throw new Error(detail);
      default:
        throw new Error('Unknown Error');
    }
  }
}
