import _ from 'underscore';
import { formatISO, isEqual, isValid as validDate } from 'date-fns';

export default class Model {
  isWorkspace = false;
  workspace = null;

  get fields() {
    return {};
  }

  get isDirty() {
    if (this.isWorkspace) {
      return true; // Workspaces are always so dirty!
    }

    // return _(_.keys(this.fields)).any((f) => {
    return Object.keys(this.fields).some((f) => {
      const v = this[f];
      const wv = this.workspace[f];
      switch (true) {
        case _(v).isDate():
          return !isEqual(v, wv);
        default:
          return v !== wv;
      }
    });
  }

  copyFrom(model) {
    const m = model || {};
    Object.keys(this.fields).forEach((f) => {
      switch (f) {
        default:
          this[f] = m[f] ?? m[this.fields[f]];
      }
    });
  }

  serialize() {
    return Object.keys(this.fields).reduce((model, field) => {
      const key = this.fields[field];
      const value = this[field];

      switch (true) {
        case value instanceof Model:
          model[key] = value.serialize();
          break;
        case _(value).isDate():
          model[key] = validDate(value) ? formatISO(value) : null;
          break;

        default:
          model[key] = this[field];
          break;
      }

      return model;
    }, {});
  }

  clone() {
    return new this.constructor(this.serialize());
  }

  commit() {
    if (this.isWorkspace) {
      return;
    }

    this.copyFrom(this.workspace.serialize());
  }

  rollback() {
    if (this.isWorkspace) {
      return;
    }

    this.workspace.copyFrom(this.serialize());
  }
}
