import Model from '@/model/model';

export class Address extends Model {
  address1 = null;
  address2 = null;
  city = null;
  state = null;
  zip = null;
  country = null;

  static create(address) {
    return new Address(address);
  }

  constructor(address) {
    super();
    this.copyFrom(address);
  }

  get fields() {
    return {
      ...super.fields,
      address1: 'address1',
      address2: 'address2',
      city: 'city',
      state: 'state',
      zip: 'zip',
      country: 'country'
    };
  }
}

export default Address;
