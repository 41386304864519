import Vue from 'vue';
import Component from 'vue-class-component';
import VueMeta from 'vue-meta';
import VueCookies from 'vue-cookies';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import App from '@/App.vue';
import getRouter from '@/router';
import getStore from '@/store/portal_store';
import { format as dateFormat, parseISO } from 'date-fns';
import downloader from '@/util/downloader';
import { getApi } from '@/service/api';
import _ from 'underscore';
import VueMask from 'v-mask';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import 'vue-search-select/dist/VueSearchSelect.css';

async function main() {
  Vue.config.productionTip = false;

  Vue.use(VueCookies);
  Vue.use(BootstrapVue);
  Vue.use(IconsPlugin);

  Component.registerHooks(['beforeRouteEnter', 'beforeRouteUpdate']);

  const store = await getStore();
  downloader.setStore(store);
  getApi().setStore(store);
  const router = getRouter(store);

  Vue.use(VueMeta, {
    refreshOnceOnNavigation: true
  });

  Vue.use(VueMask);

  // TODO: Move this to its own file
  Vue.mixin({
    metaInfo: {
      changed: (info) => {
        store.commit('setTitle', info.title);
      }
    }
  });

  // TODO: Move this to its own utility file
  Vue.filter('formatDate', (d) => {
    try {
      const date = _.isDate(d) ? d : parseISO(d);
      return 'Invalid Date' === date.toString() ? '' : dateFormat(date, 'M/d/yyyy');
    } catch (err) {
      console.log('Input: %s', d);
      console.debug(err);
      return '';
    }
  });

  const app = new Vue({
    router,
    store,
    render: (h) => h(App)
  });
  store.commit('setVue', app);
  app.$mount('#app');
}

main();
