import axios from 'axios';

export const MIME_TYPE = 'application/json';

let api = null;

export function getApi() {
  if (!!api) {
    return api;
  }

  api = axios.create({
    baseURL: '/api',
    withCredentials: true,
    headers: {
      Accept: MIME_TYPE,
      'Content-Type': MIME_TYPE
    }
  });

  api.interceptors.request.use(
    (config) => {
      api.$store?.dispatch?.('incrementLoadingRequests');
      return config;
    },
    (error) => {
      api.$store?.dispatch?.('decrementLoadingRequests');
      return Promise.reject(error);
    }
  );

  api.interceptors.response.use(
    (response) => {
      api.$store?.dispatch?.('decrementLoadingRequests');
      return response;
    },
    (error) => {
      api.$store?.dispatch?.('decrementLoadingRequests');
      // TODO: Detect no longer logged in and destroy the session
      return Promise.reject({ status: error.response, detail: error.response.data?.detail });
    }
  );

  api.setStore = function (store) {
    api.$store = store;
  };

  return api;
}
