<template>
    <div class="page-footer">
        &copy; {{ copyrightYear }} United Brotherhood of Carpenters
    </div>
</template>
<script>

import {Vue, Component} from 'vue-property-decorator';

@Component
export default class Footer extends Vue {
    get copyrightYear() {
        return new Date().getFullYear();
    }
}

</script>
<style scoped>
    .page-footer {
        border-top: 1px solid black;
        font-size: 10px;
        background-color: #A28058;
        color: white;
        text-align: center;
        text-shadow: 1px 1px 1px black;
    }
</style>
