<template>
  <b-modal
    title="Your Username Is:"
    body-bg-variant="light"
    header-bg-variant="dark"
    header-text-variant="white"
    footer-bg-variant="dark"
    footer-text-variant="white"
    v-model="isShown"
    size="sm"
    centered>
    <b-card body-bg-variant="light" class="border-0">
      <div class="text-center h4">{{ username }}</div>
    </b-card>
    <template #modal-footer="{ close }">
      <b-button size="sm" variant="success" @click="close">Close</b-button>
    </template>
  </b-modal>
</template>
<script>
import { Vue, Component } from 'vue-property-decorator';

@Component
export default class ShowUsernameModal extends Vue {
  get isShown() {
    return this.$store.getters.showUsernameModal;
  }

  set isShown(value) {
    this.$store.commit('toggleShowUsernameModal', value);
  }

  get user() {
    return this.$store.getters['session/currentUser'] || {};
  }

  get username() {
    return this.user.username;
  }
}
</script>
<style scoped>
.given-username {
  font-size: 25px;
  font-weight: bold;
}
</style>
