import Model from '@/model/model';
import {parseISO} from 'date-fns';
import _ from 'underscore';

export default class TrainingHistory extends Model {
    ubcId = null;
    className = null;
    endDate = null;
    expirationDate = null;

    static create(trainingHistory) {
        return new TrainingHistory(trainingHistory);
    }

    constructor(trainingHistory) {
        super();
        this.copyFrom(trainingHistory);
    }

    get fields() {
        return {
            ...super.fields,
            ubcId: 'ubc_id',
            className: 'class_name',
            endDate: 'end_date',
            expirationDate: 'expiration_date'
        };
    }

    copyFrom(trainingHistory) {
        super.copyFrom(trainingHistory);

        _.each(['endDate', 'expirationDate'], key => {
            const v = this[key];
            const d = _.isDate(v) ? v : parseISO(v);
            this[key] = 'Invalid Date' === d.toString() ? null : d;
        });
    }
}