import _ from 'underscore';
import Model from '@/model/model';

export default class User extends Model {
    ubcId = null;
    firstName = null;
    middleName = null;
    lastName = null;
    suffix = null;
    username = null;
    mustChangePassword = null;

    static isValid(user) {
        return !!user && !!user.ubcId;
    }

    static create(user) {
        return new User(user);
    }

    constructor(user) {
        super();
        this.copyFrom(user);
    }

    get fields() {
        return {
            ...super.fields,
            ubcId: 'ubc_id',
            firstName: 'first_name',
            middleName: 'middle_name',
            suffix: 'suffix',
            username: 'username',
            mustChangePassword: 'must_change_password'
        };
    }

    copyFrom(user) {
        super.copyFrom(user);
        const mustChange = user.mustChangePassword || user.must_change_password;

        if (!!mustChange) {
            if (_.isBoolean(mustChange)) {
                this.mustChangePassword = mustChange;
            } else {
                this.mustChangePassword = _.isString(mustChange) ? 'Y' === mustChange.trim().toUpperCase() : null;
            }
        }
    }
}
